<template>
    <div class="container-fluid">
        <div class="col-md-12">
            <h4 class="title-in-h">{{info_page.header}}</h4>
        </div>
        <breadcrumb >
            <breadcrumb-item  v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
                <router-link :to="{path:breadcrumb.path}"  class="color-blue-var">{{breadcrumb.name}}</router-link>
            </breadcrumb-item>
            <breadcrumb-item active v-else>
                {{breadcrumb.name}}
            </breadcrumb-item>
        </breadcrumb>

        <card class="mt-2" v-loading="screenLoading"
              element-loading-lock="true"
              :element-loading-text="screenText"
              element-loading-spinner="el-icon-loading">
            <div>

                <div class="row d-none">
                    <div class="col-md-8">
                        <div class="btn-taps text-center mt-2 mb-1">

                            <button v-for="i_lang in info_page.langs" class="btn btn-wd btn-default ml-1 mr-1" :class="info_page.tab_lang===i_lang.locale?'btn-info':'btn-outline'" @click="info_page.tab_lang=i_lang.locale">{{i_lang.name}}</button>

                        </div>
                    </div>
                </div><!--   Hacer el cambio de idioma -->




                <form>
                    <div class="row">
                        <div class="col-md-12">

                            <tinymce-editor api-key="kdft9s69zgphk86767gp0b10ykt0iljm2m8a5o9zy6wcu03k" v-model="content" :init="{height: 500}"> </tinymce-editor>

                        </div>

                    </div>
                    <div class="text-center mt-3 mb-3">
                        <button type="submit" class="btn btn-success btn-fill btn-wd mr-2" @click.prevent="sendForm(info_page.action)">
                            {{info_page.header}}
                        </button>

                        <button type="buttton" class="btn btn-danger btn-fill btn-wd" @click.prevent="$router.push({name: 'indexTerms'})">
                            {{$t('form.cancel')}}
                        </button>
                    </div>
                    <div class="clearfix"></div>
                </form>
            </div>
        </card>
    </div>
</template>
<script>
    import swal from 'sweetalert2'
    import { Checkbox, Breadcrumb, BreadcrumbItem } from 'src/components/index'
    import PropertyService from '../../../js/services/PropertyService';
    import LogFunctions from '../../../js/functions/LogFunctions';
    import LogService from '../../../js/services/LogService';

    const propertyService = new PropertyService();
    // es modules
    import Editor from '@tinymce/tinymce-vue';
    import { vueTopprogress } from 'vue-top-progress';
    import EventBus from "../../../js/helpers/EventBus";
    // commonjs require
    // NOTE: default needed after require
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'
    import { mapGetters } from "vuex";
    import moment from 'moment-timezone';
    const logFunctions = new LogFunctions();
    const logService = new LogService();

    export default {
        computed: {
            ...mapGetters(["currentUser", "selected_property","permissions","writting_permission"]),
        },
        components: {
            Breadcrumb,
            BreadcrumbItem,
            'tinymce-editor': Editor,
            vueTopprogress
        },
        data() {
            return {
                log_page:{
                    action: null,
                    section: null,
                    property_id: null,
                    data: [],
                    date: new Date(),
                    object_id: null,
                    user_id: null,
                },
                screenLoading: false,
                screenText: '',
                content:'',
                breadcrumbs:[
                    {
                        name: this.$t('property.index.home'),
                        path: '/admin/properties',
                        type: '',
                    },
                    {
                        name: this.$t('sidebar.terms'),
                        path: '/admin/property/terms-and-politics',
                        type: '',
                    },

                    {
                        name: this.$t('property.new.header'),
                        path: '',
                        type: 'active',
                    }

                ],

                info_page:{
                    original_info:{},
                    section_name: 'property',
                    action: 'add',
                    page: '',
                    header: this.$t('property.new.header'),
                    button:  this.$t('property.new.header'),
                    tab_lang: 'es',
                    langs:[{
                        name: this.$t('locale_es'),
                        locale: 'es'
                    },{
                        name: this.$t('locale_en'),
                        locale: 'en'
                    },]
                },
                property: {
                    _id: '',
                    privacy : '',
                    terms : '',
                    payment_policy : '',

                },
                section: 'PROPERTY'

            }
        },
        created(){
            this.initPage(this.$route.params.id);
            this.$store.dispatch('CHANGE_SECTION',this.section);
        },
        methods: {

            changeProperty(){
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {

                    property_id = this.selected_property._id;
                    if(this.$route.name==='editPrivacis' || this.$route.name==='editPolitics' || this.$route.name==='editTerms'){
                        this.initPage(property_id);
                    }
                })
            },
            configLogs(property_id, section_name){
                this.log_page.section = logFunctions.findSection(section_name)._id
                this.log_page.property_id = property_id;
                this.log_page.user_id = this.currentUser._id;
                this.log_page.action = 1;
                this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
            },
            async createLog(object_id, action, data = []){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                paramsLog.action = action;
                paramsLog.data = data;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                await logService.create(paramsLog);
            },

            initPage(property_id = null){
                let routeName = this.$route.name;
                this.initLoading();



                //let property_id = this.$route.params.id;
                let info = null;
               //console.log(property_id, 'property_id');
                this.setHeaders(routeName);
                this.property._id = property_id;

                this.configLogs(property_id, this.info_page.section_name);
               //console.log('this.selected_property', this.selected_property);

               propertyService.getPropertyId(property_id).then(response => {
                    let data = response.data.data.property;
                    this.info_page.original_info = JSON.parse(JSON.stringify(data))
                    switch (routeName) {
                        case 'editPrivacis':
                            this.content = data.privacy;
                            break;
                        case 'editTerms':
                            this.content = data.terms;
                            break;
                        case 'editPolitics':
                            this.content = data.payment_policy;
                            break;
                    }
                    setTimeout(()=>{
                        this.closeLoading();
                    }, 500)
               });

               /* let data = this.selected_property;
                
               */



            },
            setHeaders(routeName){

                this.info_page.page = routeName;
                switch (routeName) {
                    case 'editPrivacis':
                        this.breadcrumbs[this.breadcrumbs.length-1].name = this.$t('property.terms.set_privacy');
                        this.info_page.header = this.$t('property.terms.set_privacy');


                        break;
                    case 'editTerms':
                        this.breadcrumbs[this.breadcrumbs.length-1].name = this.$t('property.terms.set_terms');
                        this.info_page.header = this.$t('property.terms.set_terms');
                        break;
                    case 'editPolitics':
                        this.breadcrumbs[this.breadcrumbs.length-1].name = this.$t('property.terms.set_politics');
                        this.info_page.header = this.$t('property.terms.set_politics');
                        break;
                }
            },
            sendForm(action){
                this.updateItem();

            },
            updateItem(){

                this.initLoading('Guardando');
                let info = null;
                let routeName = this.info_page.page;
                switch (routeName) {
                    case 'editPrivacis':
                        info = {privacy:this.content};
                        break;
                    case 'editTerms':
                        info = {terms:this.content};
                        break;
                    case 'editPolitics':
                        info = {payment_policy:this.content};
                        break;
                }


                propertyService.updatePolitics(info, this.property._id)
                    .then(async (response) => {

                        let propertyInfo = response.data.data.property;
                        let changes = await logFunctions.cheackProp(this.info_page.original_info, propertyInfo, this.info_page.section_name)
                        if(changes.length){
                            await this.createLog(propertyInfo._id, 1, changes);
                        }

                        this.$store.dispatch('UPDATE_PROPERTY', response.data.data.property);
                       //console.log('contrato', response.data);
                        this.closeLoading();
                        this.showSwal(this.$t('property.edit.msg'));
                    })
                    .catch(error => {
                       //console.log(error);
                    })
                    .finally(() => {
                    });
            },
            showSwal (msg) {

                swal({
                    title: this.$t('contract.new.modal'),
                    text: msg,
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    type: 'success'
                }).then(()=> {
                    this.$router.push({
                        name: 'indexTerms'
                    });
                });
            },
            initLoading(text = 'Cargando...') {
                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            }

        },

        mounted() {
            this.changeProperty()
        }


    }

</script>
<style lang="scss">

</style>
